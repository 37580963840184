export const resources = {
    en: {
      translation: {
        "user": "User",
        "logout" : "Log out",
        "client monitorings": "Client monitoring",
        "login": "Log in",
        "An error occurred": "An error occurred",
        'Username is required': "Username is required",
        'Password is required': "Password is required",
        "Username": "Username",
        "Password": "Password",
        "Incorrect username or password": "Incorrect username or password",
        "Page not found": "Page not found",
        "Something has gone wrong": "Something has gone wrong"
      }
    },
    ru: {
        translation: {
          "user": "Пользователь",
          "logout": "Выйти",
          "client monitorings": "Клиентские мониторинги",
          "login": "Вход в личный кабинет",
          "An error occurred": "Произошла ошибка",
          'Username is required': "Укажите имя пользователя",
          'Password is required': "Укажите пароль",
          "Username": "Имя пользователя",
          "Password": "Пароль",
          "Incorrect username or password": "Неверное имя пользователя или пароль",
          "Page not found": "Страница не найдена",
          "Something has gone wrong": "Что-то пошло не так"
        }
      }
  };
