import axiosConfig from '../config/axiosConfig'
import { getFieldFromJwtToken } from './../utils/authUtils'

export default class UserService {

    async login(login, password) {
        return await axiosConfig.post('user/login', {
            password: password,
            login: login
        });
    }

    async logout() {
        let userUid = getFieldFromJwtToken('id');
        return await axiosConfig.post('user/logout', {
            userUid: userUid
        });
    }

    async refreshJwtToken() {
       return await axiosConfig.post('user/refresh-jwt-token', {}, {withCredentials: true});
    }

}

