import axios from 'axios';
import { Cookies } from "react-cookie";
import { API_SERVER, ALLOW_ORIGIN } from '../config/constant';

const instance = axios.create({
    baseURL: API_SERVER
});

const cookies = new Cookies();

instance.defaults.withCredentials = true;
instance.defaults.headers.common['Content-type'] = 'application/json';
instance.defaults.headers.common['Access-Control-Allow-Origin'] = ALLOW_ORIGIN;

instance.interceptors.request.use(request => {
    const jwtToken = localStorage.getItem('jwt_token');
    request.headers.common['Authorization'] = 'Bearer ' + jwtToken;
    return request;
})

instance.interceptors.response.use(response => {
    return response;
}, async error => {
    if (error.response.status === 401) {

        let response = await instance.post('/user/refresh-jwt-token', {}, {
            headers: {
                'Authorization': ''
            }
        });

        try {
            let token = response.data.jwtToken;
            localStorage.setItem('jwt_token', token);
            error.config.headers.authorization = token;
            //retry request
            return instance.request(error.config)
        } catch (error) {
            cookies.remove('refresh_token', { path: '/', sameSite: 'none', secure: true })
            localStorage.removeItem('jwt_token');
            window.location.replace('/auth/signin');
        }

        return error.response;

    }
    return error;
});

export default instance;