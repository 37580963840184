import { useTranslation } from 'react-i18next';

const NotFound = (props) => {
    const { t } = useTranslation()
    return (
        <div className="block-not-found">
            <h1>404</h1>
            <h1>{t('Page not found')}</h1>
        </div>
    )
}

export default NotFound;