import { SHOW_NOTIFICATION } from './actions';

export const initialState = {
    isVisible: false,
    text: '',
};

const notificationReducer = (state = initialState, action) => {
    switch (action.type) {
        case SHOW_NOTIFICATION: {
            const { isVisible, text  } = action.payload;
            return {
                ...state,
                isVisible,
                text,
            };
        }
       
        default: {
            return { ...state };
        }
    }
};

export default notificationReducer;
