import NotFound from '../../views/NotFound';
import {getUserRoles} from '../../utils/authUtils'

const RoleGuard = ({ children, allowedRoles }) => { 
    if (allowedRoles) {
        const userRoles = getUserRoles();
        const canIWork = allowedRoles?.some((role) => userRoles?.includes(role));      
        if (!canIWork) {
            return  <NotFound />;
        }
    }
    return children;
};

export default RoleGuard;
