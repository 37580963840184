const getFieldFromJwtToken = (fieldName) => {

    let token = localStorage.getItem('jwt_token');
    if (token == undefined || token == '')
        return '';

    var base64Url = token.split('.')[1];
    if (base64Url === undefined || base64Url === '') return;
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    
    return JSON.parse(jsonPayload)[fieldName];
}

const getUserRoles = () => {
   return getFieldFromJwtToken('roles')?.split(',');
}

export { getFieldFromJwtToken, getUserRoles }

