import React, { useContext, useState } from 'react';
import { ListGroup } from 'react-bootstrap';
import { NavLink, Redirect } from 'react-router-dom';
import { Cookies } from 'react-cookie'
import { useDispatch } from 'react-redux';

import NavIcon from '../NavIcon';
import NavBadge from '../NavBadge';

import { ConfigContext } from '../../../../../contexts/ConfigContext';
import * as actionType from '../../../../../store/actions';
import useWindowSize from '../../../../../hooks/useWindowSize';

import UserService from '../../../../../services/UserService';

const NavItem = ({ layout, item }) => {
    const windowSize = useWindowSize();
    const configContext = useContext(ConfigContext);
    const { dispatch } = configContext;
    const dispatcher = useDispatch();
    const userService = new UserService();
    const cookies = new Cookies();

    const [isLogout, setIsLogout] = useState(false)

    let itemTitle = item.title;
    if (item.icon) {
        itemTitle = <span className="pcoded-mtext">{item.title}</span>;
    }

    let itemTarget = '';
    if (item.target) {
        itemTarget = '_blank';
    }


    let subContent;
    if (item.external) {
        subContent = (
            <a href={item.url} target="_blank" rel="noopener noreferrer">
                <NavIcon items={item} />
                {itemTitle}
                <NavBadge items={item} />
            </a>
        );
    } else {

        if (item.id == 'logout') {

            const onLogout = () => {
                userService.logout()
                    .then(r => {
                        cookies.remove('refresh_token', { path: '/', sameSite: 'none', secure: true })
                        localStorage.removeItem('jwt_token');
                        // window.location.reload();
                        // window.location.href = '/auth/signin'
                        window.location.replace('/auth/signin');
                    })
                    .catch(error => {
                        dispatcher({
                            type: actionType.SHOW_NOTIFICATION,
                            payload: { isVisible: true, text: 'Произошла ошибка' }
                        });
                    });

                    
            }

            subContent = (
                <NavLink to='#' onClick={onLogout} className="nav-link" exact={true} target={itemTarget}>
                    <NavIcon items={item} />
                    {itemTitle}
                    <NavBadge items={item} />
                </NavLink>
            )
        } else {
            subContent = (
                <NavLink to={item.url} className="nav-link" exact={true} target={itemTarget}>
                    <NavIcon items={item} />
                    {itemTitle}
                    <NavBadge items={item} />
                </NavLink>
            );
        }

    }
    let mainContent = '';
    if (layout === 'horizontal') {
        mainContent = (
            <ListGroup.Item as="li" bsPrefix=" " onClick={() => dispatch({ type: actionType.NAV_CONTENT_LEAVE })}>
                {subContent}
            </ListGroup.Item>
        );
    } else {
        if (windowSize.width < 992) {
            mainContent = (
                <ListGroup.Item as="li" bsPrefix=" " className={item.classes} onClick={() => dispatch({ type: actionType.COLLAPSE_MENU })}>
                    {subContent}
                </ListGroup.Item>
            );
        } else {
            mainContent = (
                <ListGroup.Item as="li" bsPrefix=" " className={item.classes}>
                    {subContent}
                </ListGroup.Item>
            );
        }
    }

    return <React.Fragment>{mainContent}</React.Fragment>;
};

export default NavItem;
