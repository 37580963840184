import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import ReactDOM from 'react-dom';

import { Provider } from 'react-redux';
import { ConfigProvider } from './contexts/ConfigContext';
import IPService from './services/IPService';
import './index.scss';
import App from './App';
import { store, persister } from './store';

import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import { resources } from './localization'

const ipService = new IPService();

const getCountry = async () => {

    let countryCode = 'en';
    try {
        var res = await ipService.getUserDataByApi();
        countryCode = res.data.country_code;
    }
    finally {
        if (countryCode == 'RU') {
            document.title = 'Мониторинги | Модуль'
        } else {
            document.title = 'Monitoring | MODUL'
            countryCode = 'en';
        }
    }

    return countryCode.toLocaleLowerCase();
}

const Init = async () => {

    const lng = await getCountry();
    i18n.use(initReactI18next).init({
        lng: lng,
        // debug: true,
        resources: resources
    });

    ReactDOM.render(
        <Provider store={store}>
            <ConfigProvider>
                <App />
            </ConfigProvider>
        </Provider>,
        document.getElementById('root')
    );
}
Init();


