import { Redirect } from 'react-router-dom';

const AuthGuard = ({ children }) => {

    const token = localStorage.getItem('jwt_token');
    if (!token) {
        return <Redirect to="/auth/signin" />;
    }

    return children;
};

export default AuthGuard;
