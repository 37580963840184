import React, { Suspense, Fragment, lazy } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';

import Loader from './components/Loader/Loader';
import AdminLayout from './layouts/AdminLayout';

import GuestGuard from './components/Auth/GuestGuard';
import AuthGuard from './components/Auth/AuthGuard';
import RoleGuard from './components/Auth/RoleGuard';


export const renderRoutes = (routes = []) => (
    <Suspense fallback={<Loader />}>
        <Switch>
            {routes.map((route, i) => {
                const Guard = route.guard || Fragment;
                const Layout = route.layout || Fragment;
                const Component = route.component;
                return (
                    <Route
                        key={i}
                        path={route.path}
                        exact={route.exact}
                        render={(props) => (
                            <Guard route={route.path}>
                                <Layout>
                                    {route.routes ? (
                                        renderRoutes(route.routes)
                                    ) : (
                                        <RoleGuard allowedRoles={route.allowedRoles}>
                                            <Component {...props} />
                                        </RoleGuard>
                                    )}
                                </Layout>
                            </Guard>
                        )}
                    />
                );
            })}
        </Switch>
    </Suspense>
);

const routes = [
    {
        exact: true,
        guard: GuestGuard,
        path: '/auth/signin',
        component: lazy(() => import('./views/auth/signin/SignIn1'))
    },
    {
        layout: AdminLayout,
        guard: AuthGuard,
        routes: [
            {
                exact: true,
                path: '/start',
                component: lazy(() => import('./views/StartPage'))
            },
            {
                exact: true,
                path: '/',
                component: lazy(() => import('./views/StartPage'))
            },
            {
                exact: true,
                path: '/asbestos',
                allowedRoles: ['asbestos'],
                component: lazy(() => import('./views/cargo/Asbestos'))
            },
            {
                exact: true,
                path: '/china/containers-at-terminal',
                allowedRoles: ['china'],
                component: lazy(() => import('./views/china/ContainersAtTerminal'))
            },
            {
                exact: true,
                path: '/china/container-movements',
                allowedRoles: ['china'],
                component: lazy(() => import('./views/china/СontainerMovements'))
            },
            {
                exact: true,
                path: '/china/containers-shipped-to-china',
                allowedRoles: ['china'],
                component: lazy(() => import('./views/china/CntrsShippedToChina/CntrsShippedToChina'))
            },
            {
                exact: true,
                path: '/china/containers-in-china',
                allowedRoles: ['china'],
                component: lazy(() => import('./views/china/CntrsInChina/CntrsInChina'))
            },
            {
                exact: true,
                path: '/china/containers-shipped-from-china',
                allowedRoles: ['china'],
                component: lazy(() => import('./views/china/CntrsShippedFromChina/CntrsShippedFromChina'))
            },
            {
                exact: true,
                path: '*',
                component: lazy(() => import('./views/NotFound'))
            },
        ]
    }
];

export default routes;
