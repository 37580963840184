import { combineReducers } from '@reduxjs/toolkit';
import { reducer as formReducer } from 'redux-form';
import accountReducer from './accountReducer';
import notificationReducer from './notificationReducer';


const reducers = combineReducers({
    account: accountReducer,
    notification: notificationReducer,
    form: formReducer,
});

export default reducers;
