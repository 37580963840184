import React from 'react';
import { useEffect, useState } from 'react'
import { BrowserRouter as Router } from 'react-router-dom';
import history from './history';
import routes, { renderRoutes } from './routes';
import { BASENAME } from './config/constant';
import IPService from './services/IPService';
import { useTranslation } from 'react-i18next'

const App = () => {

    // const { i18n } = useTranslation()

    // useEffect(() => {
    //     getIpUserData()
    // }, [])

    // const ipService = new IPService();
    // const getIpUserData = async () => {

    //     let countryCode = 'EN';
    //     try {
    //         var data = await ipService.getUserDataByApi();
    //         countryCode = data.data.country_code;
    //     }
    //     finally {
    //         if (countryCode == 'RU') {
    //             i18n.changeLanguage('ru');
    //             document.title = 'Мониторинги | Модуль'
    //         } else {
    //             document.title = 'Monitoring | MODUL'
    //         }
    //     }
    // }

    return (
        <React.Fragment>
            <Router history={history} basename={BASENAME}>{renderRoutes(routes)}</Router>
        </React.Fragment>
    );
};

export default App;
