import i18next from "i18next";
import { getUserRoles } from './utils/authUtils'

const menuItems = () =>
    [
        {
            id: 'userGroup',
            title: i18next.t("user"),
            type: 'group',
            icon: 'icon-navigation',
            children: [
                {
                    id: 'userItem',
                    title: 'userName',
                    type: 'collapse',
                    icon: 'feather icon-user',
                    bottomText: '',
                    children: [
                        {
                            id: 'logout',
                            title: i18next.t("logout"),
                            type: 'item',
                            url: '/logout',
                            icon: 'feather icon-log-out'
                        }
                    ]
                }
            ]
        },
        {
            id: 'asbestosGroup',
            title: 'Мониторинги',
            type: 'group',
            icon: 'icon-navigation',
            children: [
                {
                    id: 'asbestos',
                    title: 'Асбест',
                    type: 'item',
                    url: '/asbestos',
                    allowedRoles: ['asbestos'],
                    icon: 'feather icon-package'
                }
            ]
        },
        {
            id: 'ChinaGroup',
            title: 'China operations',
            type: 'group',
            icon: 'icon-navigation',
            children: [
                {
                    id: 'china_containers_at_terminal',
                    title: 'Containers at terminal',
                    type: 'item',
                    url: '/china/containers-at-terminal',
                    allowedRoles: ['china'],
                    icon: 'feather icon-file-text'
                },
                {
                    id: 'china_container_movements',
                    title: 'Container movements',
                    type: 'item',
                    url: '/china/container-movements',
                    allowedRoles: ['china'],
                    icon: 'feather icon-file-text'
                },
                {
                    id: 'china_container_to_china',
                    title: 'Containers shipped to China',
                    type: 'item',
                    url: '/china/containers-shipped-to-china',
                    allowedRoles: ['china'],
                    icon: 'feather icon-file-text'
                },
                {
                    id: 'china_container_in_china',
                    title: 'Containers in China',
                    type: 'item',
                    url: '/china/containers-in-china',
                    allowedRoles: ['china'],
                    icon: 'feather icon-file-text'
                },
                {
                    id: 'china_container_shipped_from_china',
                    title: 'Containers shipped from China',
                    type: 'item',
                    url: '/china/containers-shipped-from-china',
                    allowedRoles: ['china'],
                    icon: 'feather icon-file-text'
                }
            ]
        }
    ];


const allowedMenuItems = () => {
    const userRoles = getUserRoles();
    return menuItems().filter(g => (
        g.id == 'userGroup' ||
        g.allowedRoles?.some(ar => userRoles?.includes(ar)) ||
        g.children?.some(gc => gc.allowedRoles?.some(gca => userRoles?.includes(gca)))
    ));
}

export default allowedMenuItems;
